var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "hire-us" },
    [
      _c("div", { staticClass: "hire-us-container" }, [
        _c("div", [
          _vm.showBackButton
            ? _c(
                "div",
                { staticClass: "button-container" },
                [
                  _c(
                    "b-link",
                    {
                      staticClass: "py-0",
                      attrs: { variant: "link", hidden: _vm.backButtonHidden },
                      on: { click: _vm.backButtonClicked },
                    },
                    [
                      _c("fa-icon", { attrs: { icon: "chevron-left" } }),
                      _vm._v("\n          Back\n        "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row flex-wrap justify-content-center p-1",
            },
            [
              _c(
                "div",
                { staticClass: "hire-us-details" },
                [
                  _vm.company && _vm.companyId
                    ? _c("div", [
                        _c("div", [
                          _c("h4", { staticClass: "hire-us-company-name" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.company.name) +
                                "\n              "
                            ),
                          ]),
                          _vm.jurisdictionDisplayed
                            ? _c("p", { staticClass: "home-state" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.jurisdictionLabelDisplayed) +
                                    "\n                "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "home-state-jurisdiction" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.jurisdictionDisplayed) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _c("router-view", {
                    attrs: { "payment-method-errors": _vm.paymentMethodErrors },
                    on: { toggleButtonVisibility: _vm.toggleButtonVisibility },
                  }),
                ],
                1
              ),
              _vm.$router.currentRoute.meta.showSummary
                ? _c(
                    "div",
                    { staticClass: "hire-us-details mt-2" },
                    [
                      _c("cart", {
                        attrs: {
                          "processing-checkout": _vm.processingCheckout,
                          "button-text": _vm.isCheckout,
                          "show-promo-code": _vm.isCheckout === "Checkout",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _c("formation-info-modal", {
        ref: "formation-info-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "formation-info-modal-complete":
            _vm.formationInfoModalCompleteHandler,
        },
        model: {
          value: _vm.companyAdded,
          callback: function ($$v) {
            _vm.companyAdded = $$v
          },
          expression: "companyAdded",
        },
      }),
      _c("company-domestic-registration-modal", {
        ref: "company-domestic-registration-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "domestic-registration-changed": _vm.domesticRegistrationChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }